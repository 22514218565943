import "./src/assets/styles/main.scss";

import { detectPerf } from "./src/utils/detectPerf";
import { gsap, CSSPlugin } from 'gsap/all'

import { initIntersectionObserver } from './src/utils/intersectionObserver';

export const onRouteUpdate = () => {
 if (typeof window !== `undefined`) { window.scrollTo(0, 0); }
 initIntersectionObserver();
}

export const onClientEntry = () => {
 detectPerf();
 gsap.registerPlugin(CSSPlugin);
}
