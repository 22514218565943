import gsap from 'gsap';

import getAnimation from './getAnimation';
import { getPerf, PERF } from './detectPerf';

interface IntersectEl {
	el: HTMLElement | Element;
	state: boolean;
	y: number;
	ratio: number;
}

let observer: IntersectionObserver;
let elements: NodeListOf<Element>;
const cachedEls: IntersectEl[] = [];

export const getIntersectionEls = () => {
	if (getPerf() < PERF.PERF_GOOD) {
		return;
	}

	elements = document.querySelectorAll('[data-inview]');
	// Hide elements
	gsap.to(elements, {
		opacity: 0,
	});
};

export const observeIntersectionEls = () => {
	if (getPerf() < PERF.PERF_GOOD) {
		return;
	}

	elements.forEach((el: Element) => {
		cachedEls.push({
			el,
			state: false,
			y: 0,
			ratio: 0.3,
		});
		observer.observe(el);
	});
};

export const observeIntersection = () => {
	if ('IntersectionObserver' in window && getPerf() > PERF.PERF_LOW) {
		// Hide these elements
		gsap.to(elements, {
			opacity: 0,
		});

		const callback = (entries: IntersectionObserverEntry[]) => {
			entries.forEach((entry: IntersectionObserverEntry) => {
				const cachedEl = cachedEls.find((data) => data.el === entry.target);
				if (!cachedEl) {
					return;
				}

				if (!cachedEl.state && entry.intersectionRatio > cachedEl.ratio) {
					cachedEl.state = true;
					observer.unobserve(entry.target);

					// Trigger animation
					gsap.to(cachedEl.el, {
						opacity: 1,
					});
					const animate = (cachedEl.el as HTMLElement).dataset.animatein;

					if (animate) {
						getAnimation(animate, entry.target);
					}
				}
				cachedEl.y = entry.boundingClientRect.y;
			});
		};

		observer = new IntersectionObserver(callback, {
			root: null,
			rootMargin: '0px',
			threshold: [0.3],
		});

		// Keep track of elements
		if (observer) {
			observeIntersectionEls();
		}
	}
};

export const initIntersectionObserver = () => {
	elements = document.querySelectorAll('[data-inview]');

	getIntersectionEls();
	observeIntersection();
};
