import { animateDefault, animateReveal, animateProject, animateStagger } from './helpersAnimation';

export default (type: string, el: HTMLElement | Element) => {
	switch (type) {
		case 'reveal':
			animateReveal(el as HTMLElement);
			break;

		case 'stagger':
			animateStagger(el as HTMLElement);
			break;

		case 'project':
			animateProject(el as HTMLElement);
			break;

		case 'default':
			animateDefault(el as HTMLElement);
			break;

		default:
			animateDefault(el as HTMLElement);
			break;
	}
};
