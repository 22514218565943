
import gsap from 'gsap';

export const helpersAnimation = {
	duration: 0.6,
	ease: 'power4.inOut',
	stagger: '-=0.5',
};

export const animateDefault = (item: HTMLElement | Element): void => {
	gsap.fromTo(item,
		{
			y: '30px',
			opacity: '0',
		},
		{
			y: '0px',
			opacity: '1',
			duration: 1.2,
			ease: helpersAnimation.ease,
		});
};

export const animateProject = (item: HTMLElement | Element): void => {
	gsap.fromTo(item,
		{
			x: '50px',
			opacity: '0',
		},
		{
			x: '0px',
			opacity: '1',
			duration: 1,
			ease: helpersAnimation.ease,
		});
};

export const animateDefaultReverse = (item: HTMLElement | Element): void => {
	gsap.fromTo(item,
		{
			y: '0px',
			opacity: '1',
		},
		{
			y: '30px',
			opacity: '0',
			duration: helpersAnimation.duration,
			ease: helpersAnimation.ease,
		});
};

export const animateReveal = (item: HTMLElement | Element): void => {
	gsap.fromTo(item,
		{
			yPercent: 150,
		},
		{
			yPercent: 0,
			duration: 1,
			ease: helpersAnimation.ease,
		});
};

export const animateStagger = (item: HTMLElement | Element): void => {
	const tl = gsap.timeline();
	const arr: HTMLElement[] = [];

	[...item.children].forEach(child => arr.push(child.querySelector('.stagger') as HTMLElement));

	tl.fromTo(arr,
		{
			yPercent: 200,
		},
		{
			yPercent: 0,
			duration: 1,
			delay: 1,
			ease: helpersAnimation.ease,
			stagger: {
				each: 0.1,
			},
		},
		helpersAnimation.stagger);
};
