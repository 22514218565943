/*
 *	Detect Perf
 *	Detect Performance on init. Can be used to prevent animations from happening on slower devices
 */
import { detectIE } from './detectBrowser';

let perf: number;

export enum PERF {
	PERF_BAD = 0,
	PERF_LOW = 1,
	PERF_GOOD = 2,
	PERF_HIGH = 3
}

const getPerfs = () => {
	const array: number[] = [];
	const start = (window.performance || Date).now();

	for (let i = 0; i < 20000; i += 1) {
		const calculation = Math.sin(Math.random()) ** 2;
		array.push(calculation);
	}

	const end = (window.performance || Date).now();
	return end - start;
};

export const detectPerf = () => {
	const isInternetExplorer = detectIE();
	if (isInternetExplorer) {
		perf = PERF.PERF_BAD;
	} else {
		const timing = getPerfs();

		if (timing < 7) {
			perf = PERF.PERF_HIGH;
		} else if (timing < 14) {
			perf = PERF.PERF_GOOD;
		} else if (timing < 22) {
			perf = PERF.PERF_LOW;
		} else {
			perf = PERF.PERF_BAD;
		}
	}
};

export const getPerf = () => perf;
