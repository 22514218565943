export const detectIE = (): boolean => {
	if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
		const ua = navigator.userAgent;
		const isIE = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
		return isIE;
	}

	return true;
};

export const detectEdge = (): boolean => {
	if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
		const ua = navigator.userAgent;
		const isEdge = ua.indexOf('Edge ') > -1;
		return isEdge;
	}

	return true;
};

export const detectFirefox = (): boolean => {
	if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
		const ua = navigator.userAgent;
		const isFirefox = ua.indexOf('Firefox') > -1;
		return isFirefox;
	}

	return true;
};
